module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-c1e5de1643/0/cache/gatsby-remark-images-npm-7.10.0-ee368802a4-c13a0c26c6.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-815de9249e/0/cache/gatsby-plugin-google-analytics-npm-5.3.0-a7a3ddc24a-037192c535.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-9307881-2","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-b7103b4d25/0/cache/gatsby-plugin-manifest-npm-5.10.0-80908bca4d-ad7b5a4f91.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"J. Pedro Ribeiro's Personal Site","short_name":"J. Pedro Ribeiro","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"content/assets/favicon-v052020.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c96fddb57583f6f0159d87e5a3bbd8a0"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-typography-virtual-2c7fbbd5bf/0/cache/gatsby-plugin-typography-npm-5.3.0-89da4c985f-ae54fb9387.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-6a78de5544/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
